.container {
  display: flex;
  align-items: flex-start;
  width: 398px;
  padding-bottom: 30px;
  position: relative;
}
.container div {
  display: flex;
  align-items: flex-start;
  width: 398px;
}
.container div > div:nth-child(1) {
  position: absolute;
  margin-top: 60px;
}
.container input {
  width: 65%;
  height: 50px;
  border-radius: 5px;
  border-radius: 5px 0px 0px 5px;
  background-color: transparent;
  padding: 0px 22px;
}
.container input::placeholder {
  font-size: 14px;
  letter-spacing: -0.04em;
  opacity: 0.8;
}
.primaryContainer input {
  border: 1px solid var(--tertiary-color);
}
.primaryContainer input::placeholder {
  color: var(--tertiary-color);
}
.secondaryContainer input {
  border: 1px solid #ffffff;
  color: #ffffff;
}
.secondaryContainer input::placeholder {
  color: #ffffff;
}
.container input:focus {
  outline: var(--tertiary-color);
}
.container button {
  width: 35%;
  height: 50px;
  border-radius: 0px 5px 5px 0px;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.04em;
  cursor: pointer;
  transition: all 400ms;
  outline: none;
}
.container button:hover {
  opacity: 0.9;
}
.primaryContainer button {
  background: var(--tertiary-color);
  border: 1px solid var(--tertiary-color);
  color: #ffffff;
}
.secondaryContainer button {
  background: #ffffff;
  border: 1px solid #ffffff;
  color: var(--tertiary-color);
}

@media screen and (max-width: 480px) {
  .container {
    width: 100%;
  }
  .container div {
    width: 100%;
  }
  .container input {
    width: 65%;
    height: 45px;
    padding: 0px 16px;
  }
  .container input::placeholder {
    font-size: 14px;
  }
  .container button {
    width: 35%;
    height: 45px;
    font-size: 14px;
  }
}
