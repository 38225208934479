.mainContainer a {
  text-decoration: none;
  color: unset;
}
.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 191px;
  height: 33px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 400ms;
}
.button:hover {
  opacity: 0.8;
}
.button svg {
  width: 17px;
  height: 17px;
}
.button p {
  margin-left: 10px;
}
.primaryContainer {
  background-color: transparent;
  border: 1px solid #ffffff;
}
.primaryContainer svg path {
  fill: #ffffff;
}
.primaryContainer svg rect {
  fill: #ffffff;
}
.primaryContainer p {
  color: #ffffff;
}
.secondaryContainer {
  background-color: transparent;
  border: 1px solid var(--tertiary-color);
}
.secondaryContainer svg path {
  fill: var(--tertiary-color);
}
.secondaryContainer svg rect {
  fill: var(--tertiary-color);
}
.secondaryContainer p {
  color: var(--tertiary-color);
}

@media screen and (max-width: 480px) {
  .button {
    width: 230px;
    height: 40px;
  }
  .button svg {
    width: 18px;
    height: 18px;
  }
  .button p {
    margin-left: 10px;
    font-size: 16px;
  }
}
@media screen and (max-width: 372px) {
  .button {
    width: 200px;
    height: 40px;
    border-radius: 3px;
  }
  .button svg {
    width: 16px;
    height: 16px;
  }
  .button p {
    margin-left: 10px;
    font-size: 14px;
  }
}