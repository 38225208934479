.container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-color);
  overflow-x: hidden;
  flex-wrap: wrap;
}
.wrapper {
  width: 100%;
  max-width: 1250px;
  padding: 80px 50px;
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 992px) {
  .wrapper {
    padding: 80px 20px;
  }
}
@media screen and (max-width: 480px) {
  .wrapper {
    padding: 40px 20px;
  }
}