@import url(https://fonts.googleapis.com/css2?family=Sacramento&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;500;700&display=swap);
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Noto Sans JP", sans-serif;
}
html, body {
  scroll-behavior: smooth;
}
:root {
  --primary-color: #1f32fb;
  --secondary-color: #f4f4f4;
  --tertiary-color: #2B2A35;
}

.EmailButton_mainContainer__QEvxg a {
  text-decoration: none;
  color: unset;
}
.EmailButton_button__ayMvz {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 191px;
  height: 33px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 400ms;
}
.EmailButton_button__ayMvz:hover {
  opacity: 0.8;
}
.EmailButton_button__ayMvz svg {
  width: 17px;
  height: 17px;
}
.EmailButton_button__ayMvz p {
  margin-left: 10px;
}
.EmailButton_primaryContainer__3kGWf {
  background-color: transparent;
  border: 1px solid #ffffff;
}
.EmailButton_primaryContainer__3kGWf svg path {
  fill: #ffffff;
}
.EmailButton_primaryContainer__3kGWf svg rect {
  fill: #ffffff;
}
.EmailButton_primaryContainer__3kGWf p {
  color: #ffffff;
}
.EmailButton_secondaryContainer__gOy3l {
  background-color: transparent;
  border: 1px solid var(--tertiary-color);
}
.EmailButton_secondaryContainer__gOy3l svg path {
  fill: var(--tertiary-color);
}
.EmailButton_secondaryContainer__gOy3l svg rect {
  fill: var(--tertiary-color);
}
.EmailButton_secondaryContainer__gOy3l p {
  color: var(--tertiary-color);
}

@media screen and (max-width: 480px) {
  .EmailButton_button__ayMvz {
    width: 230px;
    height: 40px;
  }
  .EmailButton_button__ayMvz svg {
    width: 18px;
    height: 18px;
  }
  .EmailButton_button__ayMvz p {
    margin-left: 10px;
    font-size: 16px;
  }
}
@media screen and (max-width: 372px) {
  .EmailButton_button__ayMvz {
    width: 200px;
    height: 40px;
    border-radius: 3px;
  }
  .EmailButton_button__ayMvz svg {
    width: 16px;
    height: 16px;
  }
  .EmailButton_button__ayMvz p {
    margin-left: 10px;
    font-size: 14px;
  }
}
.Footer_mainFooter__1jfyF {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  position: relative;
}
.Footer_secondaryFooter__2bC8g {
  background-color: var(--tertiary-color);
}
.Footer_container__1iLL- {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 100%;
  padding: 10px 0px;
  max-width: 1250px;
}
.Footer_logoContainer__nbING {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
.Footer_logoContainer__nbING img {
  width: 40px;
  height: 40px;
  border-radius: 100%;
}
.Footer_logoContainer__nbING p {
  font-size: 14px;
  margin-left: 10px;
  text-transform: capitalize;
}
.Footer_primaryFooter__1Pamp .Footer_logoContainer__nbING p {
  color: var(--tertiary-color);
}
.Footer_secondaryFooter__2bC8g .Footer_logoContainer__nbING p {
  color: #ffffff;
}
.Footer_logoContainer__nbING p span {
  font-weight: bold;
  margin-right: 5px;
}
.Footer_socialContainer__Zfo_K {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
.Footer_twitterIcon__2toLh {
  cursor: pointer;
  width: 24px;
  height: 20px;
  margin-left: 20px;
}
.Footer_linkedinIcon__3z1fM {
  cursor: pointer;
  width: 24px;
  height: 20px;
  margin-left: 20px;
}
.Footer_secondaryFooter__2bC8g .Footer_twitterIcon__2toLh path {
  fill: #ffffff;
}
.Footer_secondaryFooter__2bC8g .Footer_linkedinIcon__3z1fM path {
  fill: #ffffff;
}
.Footer_circle__bRmJg {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background: rgba(255, 255, 255, 0.7);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  position: absolute;
  top: -20px;
  left: 0;
  right: 0;
  margin: auto;
}
.Footer_circle__bRmJg svg {
  margin-bottom: 5px;
}

@media screen and (max-width: 992px) {
  .Footer_container__1iLL- {
    padding: 10px 10px;
  }
}
@media screen and (max-width: 768px) {
  .Footer_container__1iLL- {
    -webkit-flex-direction: column;
            flex-direction: column;
    padding: 30px 10px;
  }
  .Footer_logoContainer__nbING {
    -webkit-order: 2;
            order: 2;
  }
  .Footer_socialContainer__Zfo_K {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 480px) {
  .Footer_container__1iLL- {
    padding: 20px 10px;
  }
  .Footer_logoContainer__nbING {
    margin-top: 5px;
  }
  .Footer_logoContainer__nbING img {
    width: 28px;
    height: 28px;
  }
  .Footer_logoContainer__nbING p {
    font-size: 15px;
    margin-left: 5px;
  }
  .Footer_socialContainer__Zfo_K {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
  }
  .Footer_twitterIcon__2toLh {
    width: 22px;
    height: 22px;
    margin-left: 25px;
  }
  .Footer_linkedinIcon__3z1fM {
    width: 22px;
    height: 22px;
    margin-left: 25px;
  }
  .Footer_circle__bRmJg {
    width: 45px;
    height: 45px;
    top: -27px;
  }
  .Footer_circle__bRmJg svg {
    width: 22.5px;
    height: 22.5px;
  }
}
.Header_mainHeader__3UXYR {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.Header_primaryHeader__F_spZ {
  background-color: var(--primary-color);
}
.Header_secondaryHeader__3FWWj {
  background-color: var(--secondary-color);
}
.Header_tertiaryHeader__2iDEI {
  background-color: var(--tertiary-color);
}
.Header_mainHeader__3UXYR a {
  text-decoration: none;
  color: unset;
  position: relative;
}
.Header_mainHeader__3UXYR a:hover {
  opacity: 0.8;
}
.Header_container__1TFlZ {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  padding: 15px 0px;
  max-width: 1250px;
  width: 100%;
}
.Header_logoContainer__cD2Sg {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
}
.Header_logoContainer__cD2Sg img {
  width: 62px;
  height: 62px;
  width: 53px;
  height: 53px;
  border-radius: 100%;
}
.Header_logoContainer__cD2Sg p {
  font-family: "Sacramento", cursive;
  font-size: 33px;
  margin-left: 12px;
}
.Header_primaryHeader__F_spZ .Header_logoContainer__cD2Sg p {
  color: #ffffff;
}
.Header_secondaryHeader__3FWWj .Header_logoContainer__cD2Sg p {
  color: var(--tertiary-color);
}
.Header_tertiaryHeader__2iDEI .Header_logoContainer__cD2Sg p {
  color: #ffffff;
}
.Header_container__1TFlZ nav {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
.Header_container__1TFlZ nav ul {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding: 0px;
  margin: 0px;
}
.Header_container__1TFlZ nav li {
  font-family: "Noto Sans JP", sans-serif;
  font-weight: 400;
  padding: 0px;
  margin: 0px;
  list-style: none;
  font-size: 14px;
  margin: 0px 18px;
}
.Header_primaryHeader__F_spZ nav li {
  color: #ffffff;
}
.Header_secondaryHeader__3FWWj nav li {
  color: var(--tertiary-color);
}
.Header_tertiaryHeader__2iDEI nav li {
  color: #ffffff;
}
.Header_hoverDot__2UwyT {
  position: absolute;
  border-radius: 100%;
  width: 4px;
  height: 4px;
  left: 0;
  right: 0;
  margin: auto;
  margin-top: 5px;
}
.Header_primaryHeader__F_spZ .Header_hoverDot__2UwyT {
  background-color: #ffffff;
}
.Header_secondaryHeader__3FWWj .Header_hoverDot__2UwyT {
  background-color: var(--tertiary-color);
}
.Header_tertiaryHeader__2iDEI .Header_hoverDot__2UwyT {
  background-color: #ffffff;
}
.Header_menuIcon__3n8ht {
  display: none;
}
@media screen and (max-width: 992px) {
  .Header_container__1TFlZ {
    padding: 15px 10px;
  }
  .Header_container__1TFlZ nav {
    display: none;
  }
  .Header_menuIcon__3n8ht {
    display: block;
    width: 30px;  
  }
  .Header_emailButtonContainer__2uyCl {
    display: none;
  }
  .Header_logoContainer__cD2Sg img {
    width: 45px;
    height: 45px;
  }
  .Header_logoContainer__cD2Sg p {
    font-size: 26px;
    margin-left: 12px;
  }
  .Header_primaryMenu__CLuQs rect {
    fill: #ffffff !important;
  }
  .Header_secondaryHeader__3FWWj rect {
    fill: var(--tertiary-color) !important;
  }
}
@media screen and (max-width: 480px) {
  .Header_logoContainer__cD2Sg img {
    width: 50px;
    height: 50px;
  }
  .Header_logoContainer__cD2Sg p {
    font-size: 34px;
    margin-left: 10px;
  }
  .Header_menuIcon__3n8ht {
    width: 30px;
  }
}
.SideNav_container__1E6t0 {
  border: 1px solid white;
  position: fixed;
  width: 280px;
  top: 0px;
  height: 100vh;
  background-color: #ffffff;
  z-index: 999;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  transition: all 400ms;
}
.SideNav_showHeader__27YjN {
  right: 0px;
}
.SideNav_hideHeader__1cvR0 {
  right: -300px;
}
.SideNav_container__1E6t0 nav {
  display: block;
}
.SideNav_container__1E6t0 nav ul {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.SideNav_container__1E6t0 nav ul a {
  color: unset;
  text-decoration: none;
  margin-bottom: 40px;
}
.SideNav_container__1E6t0 nav ul a li {
  color: var(--tertiary-color);
}
.SideNav_times__fqs2O {
  position: absolute;
  top: 20px;
  right: 10px;
  color: var(--tertiary-color);
}
@media screen and (min-width: 993px) {
  .SideNav_container__1E6t0 {
    display: none;
  }
}

.Backdrop_container__39Kgp {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100%;
  background-color: black;
  opacity: 0.6;
  z-index: 998;
}
.Backdrop_show__1Gp5q {
    display: block;
}
.Backdrop_hide__2jgri {
    display: none;
}
.FaqBlock_container__2obIK {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: -webkit-max-content;
  width: max-content;
  padding: 15px 25px;
  border-radius: 10px;
  margin-bottom: 20px;
  position: relative;
}
.FaqBlock_container__2obIK p {
  z-index: 999;
}
.FaqBlock_container__2obIK p>strong {
  line-height: 0;
  font-size: 40px;
}
.FaqBlock_primaryContainer__1TOfb {
  background: #0f0864;
  -webkit-align-self: flex-start;
          align-self: flex-start;
}
.FaqBlock_primaryContainer__1TOfb p {
  font-size: 20px;
  line-height: 20px;
  color: #ffffff;
  font-weight: 700;
}
.FaqBlock_secondaryContainer__3z1vR {
  background: #ffffff;
  border-radius: 10px;
  -webkit-align-self: flex-end;
          align-self: flex-end;
  max-width: 847px;
}
.FaqBlock_secondaryContainer__3z1vR p {
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;
  color: var(--tertiary-color);
}
.FaqBlock_left__1w_er {
  position: absolute;
  left: -8px;
  top: 2px;
}
.FaqBlock_right__3dWUj {
  position: absolute;
  right: -12px;
  top: 4px;
}
.FaqBlock_typingBlock__QOuTR {
  width: 82px;
  height: 41px;
}
.FaqBlock_typingBlock__QOuTR p {
  font-weight: bold;
  font-size: 30px;
  color: var(--tertiary-color);
}

@media screen and (max-width: 992px) {
  .FaqBlock_container__2obIK {
    padding: 15px 20px;
  }
  .FaqBlock_primaryContainer__1TOfb {
    width: auto;
  }
  .FaqBlock_primaryContainer__1TOfb p {
    font-size: 16px;
    line-height: 18px;
  }
  .FaqBlock_secondaryContainer__3z1vR p {
    font-size: 14px;
    line-height: 23px;
  }
  .FaqBlock_secondaryContainer__3z1vR {
    width: auto;
  }
}

@media screen and (max-width: 480px) {
  .FaqBlock_container__2obIK {
    padding: 8px 12px;
    border-radius: 5px;
    margin-bottom: 15px;
  }
  .FaqBlock_primaryContainer__1TOfb p {
    font-size: 14px;
    line-height: 20px;
  }
  .FaqBlock_secondaryContainer__3z1vR {
    border-radius: 5px;
  }
  .FaqBlock_secondaryContainer__3z1vR p {
    font-size: 14px;
    line-height: 22px;
  }
  .FaqBlock_typingBlock__QOuTR {
    width: 72px;
    height: 38px;
  }
  .FaqBlock_typingBlock__QOuTR p {
    font-weight: bold;
    font-size: 24px;
    color: var(--tertiary-color);
  }
}

.FAQs_container__1RaUI {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  background-color: var(--primary-color);
  overflow-x: hidden;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}
.FAQs_wrapper__2ku1Z {
  width: 100%;
  max-width: 1250px;
  padding: 80px 50px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}
@media screen and (max-width: 992px) {
  .FAQs_wrapper__2ku1Z {
    padding: 80px 20px;
  }
}
@media screen and (max-width: 480px) {
  .FAQs_wrapper__2ku1Z {
    padding: 40px 20px;
  }
}
.Hero_container__2pDRa {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  background-color: var(--primary-color);
}
.Hero_wrapper__3W_4n {
  width: 100%;
  max-width: 1250px;
  padding: 100px 0px 0px 0px;
}
.Hero_textContainer__2MZ5i {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}
.Hero_textContainer__2MZ5i h1 {
  text-align: center;
  font-size: 75px;
  color: #ffffff;
  line-height: 100px;
  margin-bottom: 30px;
  width: 900px;
}
.Hero_textContainer__2MZ5i p {
  text-align: center;
  color: #ffffff;
  font-size: 20px;
  font-weight: 300;
  line-height: 40px;
  margin-top: 10px;
  width: 905px;
}
.Hero_details__2PyKg {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 220px;
}
.Hero_socialContainer__3Z9gN {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
.Hero_socialContainer__3Z9gN a {
  text-decoration: none;
  color: unset;
}
.Hero_twitterIcon__kAAgC {
  width: 24px;
  height: 20px;
  margin-left: 20px;
  cursor: pointer;
}
.Hero_twitterIcon__kAAgC path {
  fill: #ffffff;
}
.Hero_linkedinIcon__1I11A {
  cursor: pointer;
  width: 24px;
  height: 20px;
  margin-left: 20px;
}
.Hero_linkedinIcon__1I11A path {
  fill: #ffffff;
}
.Hero_locationContainer__3IZgv {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
.Hero_locationContainer__3IZgv div {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-left: 30px;
}
.Hero_globeIcon__3PNhQ {
  width: 25px;
  height: 25px;
}
.Hero_locationContainer__3IZgv p {
  color: #ffffff;
  margin-left: 7px;
  font-size: 17px;
  line-height: 24.62px;
}
.Hero_line__3aeQ9 {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 100%;
}
.Hero_line__3aeQ9 span {
  width: 47%;
  background-color: white;
  height: 1px;
}
.Hero_circle__C8LHM {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background: rgba(255, 255, 255, 0.7);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.Hero_circle__C8LHM svg {
  margin-top: 4px;
}

@media screen and (max-width: 992px) {
  .Hero_textContainer__2MZ5i h1 {
    font-size: 60px;
    line-height: 80px;
    margin-bottom: 15px;
    width: 90%;
  }
  .Hero_textContainer__2MZ5i p {
    font-size: 16px;
    line-height: 30px;
    margin-top: 10px;
    width: 750px;
  }
  .Hero_details__2PyKg {
    padding: 0px 10px;
  }
  .Hero_twitterIcon__kAAgC {
    width: 18px;
    height: 18px;
    margin-left: 10px;
  }
  .Hero_linkedinIcon__1I11A {
    width: 18px;
    height: 18px;
    margin-left: 10px;
  }
  .Hero_locationContainer__3IZgv div {
    margin-left: 15px;
  }
  .Hero_locationIcon__1OeJ5 {
    width: 18px;
    height: 18px;
  }
  .Hero_globeIcon__3PNhQ {
    width: 18px;
    height: 18px;
  }
  .Hero_locationContainer__3IZgv p {
    margin-left: 5px;
    font-size: 14px;
  }
}
@media screen and (max-width: 768px) {
  .Hero_textContainer__2MZ5i h1 {
    font-size: 40px;
    line-height: 60px;
  }
  .Hero_textContainer__2MZ5i p {
    font-size: 16px;
    line-height: 30px;
    margin-top: 0px;
    width: 90%;
  }
  .Hero_details__2PyKg {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
  }
  .Hero_socialContainer__3Z9gN {
    margin-bottom: 15px;
  }
  .Hero_twitterIcon__kAAgC {
    margin-left: 20px;
  }
  .Hero_linkedinIcon__1I11A {
    margin-left: 20px;
  }
  .Hero_locationContainer__3IZgv div {
    margin-left: 20px;
  }
  .Hero_line__3aeQ9 {
    margin-top: 20px;
  }
  .Hero_line__3aeQ9 span {
    width: 47%;
  }
  .Hero_circle__C8LHM {
    width: 30px;
    height: 30px;
  }
  .Hero_circle__C8LHM svg {
    margin-top: 4px;
  }
  .Hero_triangle__zoBsz {
    width: 20px;
    height: 20px;
  }
}
@media screen and (max-width: 480px) {
  .Hero_wrapper__3W_4n {
    padding: 70px 0px 0px 0px;
  }
  .Hero_textContainer__2MZ5i h1 {
    font-size: 38px;
    line-height: 48px;
    max-width: 360px;
    width: 100%;
    margin-bottom: 35px;
  }
  .Hero_textContainer__2MZ5i p {
    font-size: 18px;
    line-height: 30px;
    width: 100%;
    max-width: 360px;
  }
  .Hero_details__2PyKg {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    margin-top: 120px;
    padding: 0px 10px;
  }
  .Hero_socialContainer__3Z9gN {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 25px;
  }
  .Hero_twitterIcon__kAAgC {
    width: 22px;
    height: 22px;
    margin-left: 22px;
  }
  .Hero_linkedinIcon__1I11A {
    width: 22px;
    height: 22px;
    margin-left: 22px;
  }
  .Hero_locationContainer__3IZgv {
    margin-bottom: 2px;
  }
  .Hero_locationContainer__3IZgv div {
    margin-left: 10px;
  }
  .Hero_locationIcon__1OeJ5 {
    width: 22px;
    height: 22px;
  }
  .Hero_globeIcon__3PNhQ {
    width: 22px;
    height: 22px;
  }
  .Hero_locationContainer__3IZgv p {
    margin-left: 5px;
    font-size: 12px;
    line-height: 24.62px;
  }
  .Hero_line__3aeQ9 {
    margin-top: 10px;
  }
  .Hero_line__3aeQ9 span {
    width: 50%;
  }
  .Hero_circle__C8LHM {
    width: 25px;
    height: 25px;
    display: none;
  }
  .Hero_circle__C8LHM svg {
    margin-top: 3px;
  }
  .Hero_triangle__zoBsz {
    width: 15px;
    height: 15px;
  }
}
@media screen and (max-width: 372px) {
  .Hero_textContainer__2MZ5i h1 {
    font-size: 34px;
    line-height: 46px;
    max-width: 330px;
  }
  .Hero_textContainer__2MZ5i p {
    font-size: 16px;
    line-height: 30px;
    max-width: 320px;
  }
  .Hero_details__2PyKg {
    margin-top: 120px;
    padding: 0px 10px;
  }
  .Hero_twitterIcon__kAAgC {
    width: 20px;
    height: 20px;
    margin-left: 20px;
  }
  .Hero_linkedinIcon__1I11A {
    width: 20px;
    height: 20px;
    margin-left: 20px;
  }
  .Hero_locationContainer__3IZgv div {
    margin-left: 12px;
  }
  .Hero_locationIcon__1OeJ5 {
    width: 20px;
    height: 20px;
  }
  .Hero_globeIcon__3PNhQ {
    width: 20px;
    height: 20px;
  }
  .Hero_locationContainer__3IZgv p {
    margin-left: 5px;
    font-size: 10px;
  }
}


.Subscribe_container__1CSVV {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  width: 398px;
  padding-bottom: 30px;
  position: relative;
}
.Subscribe_container__1CSVV div {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  width: 398px;
}
.Subscribe_container__1CSVV div > div:nth-child(1) {
  position: absolute;
  margin-top: 60px;
}
.Subscribe_container__1CSVV input {
  width: 65%;
  height: 50px;
  border-radius: 5px;
  border-radius: 5px 0px 0px 5px;
  background-color: transparent;
  padding: 0px 22px;
}
.Subscribe_container__1CSVV input::-webkit-input-placeholder {
  font-size: 14px;
  letter-spacing: -0.04em;
  opacity: 0.8;
}
.Subscribe_container__1CSVV input:-ms-input-placeholder {
  font-size: 14px;
  letter-spacing: -0.04em;
  opacity: 0.8;
}
.Subscribe_container__1CSVV input::placeholder {
  font-size: 14px;
  letter-spacing: -0.04em;
  opacity: 0.8;
}
.Subscribe_primaryContainer__1NvlB input {
  border: 1px solid var(--tertiary-color);
}
.Subscribe_primaryContainer__1NvlB input::-webkit-input-placeholder {
  color: var(--tertiary-color);
}
.Subscribe_primaryContainer__1NvlB input:-ms-input-placeholder {
  color: var(--tertiary-color);
}
.Subscribe_primaryContainer__1NvlB input::placeholder {
  color: var(--tertiary-color);
}
.Subscribe_secondaryContainer__2Qj9j input {
  border: 1px solid #ffffff;
  color: #ffffff;
}
.Subscribe_secondaryContainer__2Qj9j input::-webkit-input-placeholder {
  color: #ffffff;
}
.Subscribe_secondaryContainer__2Qj9j input:-ms-input-placeholder {
  color: #ffffff;
}
.Subscribe_secondaryContainer__2Qj9j input::placeholder {
  color: #ffffff;
}
.Subscribe_container__1CSVV input:focus {
  outline: var(--tertiary-color);
}
.Subscribe_container__1CSVV button {
  width: 35%;
  height: 50px;
  border-radius: 0px 5px 5px 0px;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.04em;
  cursor: pointer;
  transition: all 400ms;
  outline: none;
}
.Subscribe_container__1CSVV button:hover {
  opacity: 0.9;
}
.Subscribe_primaryContainer__1NvlB button {
  background: var(--tertiary-color);
  border: 1px solid var(--tertiary-color);
  color: #ffffff;
}
.Subscribe_secondaryContainer__2Qj9j button {
  background: #ffffff;
  border: 1px solid #ffffff;
  color: var(--tertiary-color);
}

@media screen and (max-width: 480px) {
  .Subscribe_container__1CSVV {
    width: 100%;
  }
  .Subscribe_container__1CSVV div {
    width: 100%;
  }
  .Subscribe_container__1CSVV input {
    width: 65%;
    height: 45px;
    padding: 0px 16px;
  }
  .Subscribe_container__1CSVV input::-webkit-input-placeholder {
    font-size: 14px;
  }
  .Subscribe_container__1CSVV input:-ms-input-placeholder {
    font-size: 14px;
  }
  .Subscribe_container__1CSVV input::placeholder {
    font-size: 14px;
  }
  .Subscribe_container__1CSVV button {
    width: 35%;
    height: 45px;
    font-size: 14px;
  }
}

.Writings_container__1TXh8 {
  height: 100%;
  min-height: calc(100vh - 143px);
  background-color: var(--secondary-color);
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}
.Writings_subscribeContainerMob__2wLde {
  display: none;
}
.Writings_wrapper__7SBPI {
  width: 100%;
  max-width: 1250px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 50px 0px 0px 40px;
}
.Writings_textContainer__1hVxb {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.Writings_textContainer__1hVxb h1 {
  font-size: 60px;
  line-height: 75px;
  color: var(--tertiary-color);
  letter-spacing: -0.04em;
  margin-bottom: 20px;
}
.Writings_textContainer__1hVxb p {
  font-size: 30px;
  line-height: 35px;
  letter-spacing: -0.04em;
  color: var(--tertiary-color);
  margin-bottom: 30px;
}
.Writings_textContainer__1hVxb ul {
  margin: 0px;
  padding: 0px;
}
.Writings_textContainer__1hVxb ul li {
  padding: 0px;
  margin: 0px 0px 8px 0px;
  list-style-type: square;
  list-style-position: inside;
  font-size: 15px;
  line-height: 22px;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  color: #0f0864;
  cursor: pointer;
}

@media screen and (max-width: 992px) {
  .Writings_wrapper__7SBPI {
    padding: 50px 10px 0px 10px;
  }
  .Writings_subscribeContainerWeb__1cXTI {
    display: none;
  }
  .Writings_subscribeContainerMob__2wLde {
    display: block;
    margin-bottom: 20px;
  }
  .Writings_textContainer__1hVxb h1 {
    font-size: 50px;
  }
  .Writings_textContainer__1hVxb p {
    font-size: 26px;
  }
  .Writings_textContainer__1hVxb ul {
    margin: 0px;
    padding: 0px 0px 0px 20px;
  }
  .Writings_textContainer__1hVxb ul li {
    list-style-position: outside;
    font-size: 14px;
  }
}
@media screen and (max-width: 480px) {
  .Writings_wrapper__7SBPI {
    padding: 25px 10px 0px 10px;
  }
  .Writings_textContainer__1hVxb h1 {
    font-size: 35px;
    margin-bottom: 0px;
  }
  .Writings_textContainer__1hVxb p {
    font-size: 18px;
    margin-bottom: 20px;
    line-height: 24px;
    max-width: 360px;
    width: 100%;
  }
  .Writings_subscribeContainerMob__2wLde {
    display: block;
    margin-bottom: 40px;
  }
  .Writings_textContainer__1hVxb ul li {
    margin: 0px 0px 10px 0px;
    line-height: 24px;
    font-size: 16px;
    max-width: 300px;
    width: 100%;
  }
}
@media screen and (max-width: 372px) {
  .Writings_container__1TXh8 {
    min-height: calc(100vh);
  }  
}
.Project_container__3zmQq {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  width: 30%;
  margin-bottom: 40px;
  cursor: pointer;
  min-width: 260px;
}
.Project_container__3zmQq a {
  text-decoration: none;
  color: unset;
}
.Project_container__3zmQq:hover {
  opacity: 0.8;
}
.Project_container__3zmQq img {
  width: 44px;
  height: 44px;
}
.Project_textContainer__2lPwa {
  margin-left: 12px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.Project_textContainer__2lPwa h5 {
  font-size: 18px;
  line-height: 26px;
  color: var(--tertiary-color);
}
.Project_textContainer__2lPwa small {
  font-size: 15px;
  line-height: 22px;
  color: var(--tertiary-color);
}
@media screen and (max-width: 480px) {
  .Project_container__3zmQq {
    width: 100%;
  }
  .Project_textContainer__2lPwa {
    margin-left: 10px;
  }
}
.Projects_container__kIJQ- {
  height: 100%; 
  min-height: calc(100vh - 143px);
  background-color: var(--secondary-color);
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}
.Projects_wrapper__2Ldc_ {
  width: 100%;
  max-width: 1250px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 50px 0px 0px 40px;
}
.Projects_textContainer__2bMFx h1 {
  font-size: 60px;
  line-height: 75px;
  letter-spacing: -0.04em;
  color: var(--tertiary-color);
  margin-bottom: 15px;
}
.Projects_textContainer__2bMFx p {
  font-size: 30px;
  line-height: 35px;
  letter-spacing: -0.04em;
  color: var(--tertiary-color);
  margin-bottom: 60px;
}
.Projects_projectsContainer__vSNm0 {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

@media screen and (max-width: 992px) {
  .Projects_wrapper__2Ldc_ {
    padding: 50px 10px 0px 10px;
  }
  .Projects_textContainer__2bMFx h1 {
    font-size: 50px;
  }
  .Projects_textContainer__2bMFx p {
    font-size: 26px;
  }
}
@media screen and (max-width: 480px) {
  .Projects_wrapper__2Ldc_ {
    padding: 25px 10px 0px 10px;
  }
  .Projects_textContainer__2bMFx h1 {
    font-size: 35px;
    margin-bottom: 0px;
  }
  .Projects_textContainer__2bMFx p {
    font-size: 18px;
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 372px) {
  .Projects_container__kIJQ- {
    min-height: calc(100vh);
  }  
}
.Investments_container__1BvD6 {
  height: 100%;
  min-height: calc(100vh - 143px);
  background-color: var(--secondary-color);
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}
.Investments_wrapper__3i9Jp {
  width: 100%;
  max-width: 1250px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 50px 0px 0px 40px;
}
.Investments_textContainer__zteHB {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
}
.Investments_firstRow__1RZbE {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}
.Investments_textContainer__zteHB h1 {
  font-size: 60px;
  line-height: 75px;
  color: var(--tertiary-color);
  letter-spacing: -0.04em;
}
.Investments_textContainer__zteHB p {
  font-size: 30px;
  line-height: 35px;
  letter-spacing: -0.04em;
  color: var(--tertiary-color);
  margin-bottom: 30px;
}
.Investments_textContainer__zteHB ul {
  margin: 0px;
  padding: 0px;
}
.Investments_textContainer__zteHB ul li {
  padding: 0px;
  margin: 0px 0px 8px 0px;
  list-style-type: square;
  list-style-position: inside;
  font-size: 15px;
  line-height: 22px;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  color: #0f0864;
  cursor: pointer;
}
.Investments_subscribeContainerMob__KqRjv {
  display: none;
}

@media screen and (max-width: 992px) {
  .Investments_wrapper__3i9Jp {
    padding: 50px 10px 0px 10px;
  }
  .Investments_textContainer__zteHB h1 {
    font-size: 50px;
  }
  .Investments_textContainer__zteHB p {
    font-size: 26px;
  }
}
@media screen and (max-width: 768px) {
  .Investments_subscribeContainerWeb__14EPK {
    display: none;
  }
  .Investments_subscribeContainerMob__KqRjv {
    display: block;
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 480px) {
  .Investments_wrapper__3i9Jp {
    padding: 25px 10px 0px 10px;
  }
  .Investments_textContainer__zteHB h1 {
    font-size: 35px;
    margin-bottom: 0px;
    line-height: 75px;
  }
  .Investments_textContainer__zteHB p {
    font-size: 20px;
    margin-bottom: 30px;
    line-height: 30px;
  }
  .Investments_textContainer__zteHB ul li {
    margin: 0px 0px 10px 0px;
    line-height: 24px;
    font-size: 16px;
    max-width: 300px;
    width: 100%;
  }
  .Investments_subscribeContainerMob__KqRjv {
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 372px) {
  .Investments_container__1BvD6 {
    min-height: calc(100vh);
  }  
}
.InvestmentsBlog_container__2T95k {
  height: 100%;
  min-height: calc(100vh);
  background-color: var(--secondary-color);
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}
.InvestmentsBlog_wrapper__1xY-4 {
  width: 100%;
  max-width: 1250px;
  display: -webkit-flex;
  display: flex;
  padding: 30px 0px 40px 0px;
}
.InvestmentsBlog_leftWrapper__2DlsR {
  width: 75%;
  display: -webkit-flex;
  display: flex;
}
.InvestmentsBlog_leftContainer__abyeW {
  width: 36%;
  position: relative;
}
.InvestmentsBlog_goBack__1Wf1P {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 130px;
  margin-top: 10px;
  cursor: pointer;
}
.InvestmentsBlog_goBack__1Wf1P p {
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.04em;
  color: var(--tertiary-color);
  font-weight: bold;
  margin-left: 5px;
}
.InvestmentsBlog_leftContainer__abyeW ul {
  margin: 0px;
  padding: 0px;
  position: fixed;
  top: 260px;
}
.InvestmentsBlog_leftContainer__abyeW ul li {
  margin: 0px;
  padding: 0px;
  list-style: none;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.04em;
  color: var(--tertiary-color);
  margin-bottom: 8px;
}
.InvestmentsBlog_leftContainer__abyeW ul li a {
  color: unset;
  text-decoration: none;
}
.InvestmentsBlog_subHeading__8umX4 {
  padding-left: 20px !important;
}

.InvestmentsBlog_middleContainer__3cXE- {
  width: 64%;
}
.InvestmentsBlog_middleContainer__3cXE- h1 {
  font-size: 40px;
  line-height: 44px;
  letter-spacing: -0.04em;
  color: var(--tertiary-color);
  margin-bottom: 25px;
  padding: 10px 0px;
}
.InvestmentsBlog_middleContainer__3cXE- h2 {
  margin: 18px 0px;
  padding: 10px 0px;
  font-size: 30px;
  line-height: 40px;
  letter-spacing: -0.04em;
  color: var(--tertiary-color);
}
.InvestmentsBlog_middleContainer__3cXE- h3 {
  margin-bottom: 8px;
  padding: 10px 0px;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.04em;
  color: var(--tertiary-color);
}
.InvestmentsBlog_middleContainer__3cXE- p {
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 18px;
}
.InvestmentsBlog_middleContainer__3cXE- p strong {
  font-size: 18px;
  line-height: 18px;
  letter-spacing: -0.04em;
  color: #000000;
}
.InvestmentsBlog_middleContainer__3cXE- img {
  width: 100%;
}
.InvestmentsBlog_middleContainer__3cXE- blockquote p {
  font-style: italic;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
}
.InvestmentsBlog_middleContainer__3cXE- ul {
  padding-left: 20px;
}
.InvestmentsBlog_middleContainer__3cXE- ul li {
  font-size: 16px;
  line-height: 25px;
  letter-spacing: -0.04em;
  margin-bottom: 10px;
  color: var(--tertiary-color);
}
.InvestmentsBlog_goBackMob__2axoX {
  display: none;
}
.InvestmentsBlog_subscribeMob__4dZgt {
  display: none;
}

@media screen and (max-width: 992px) {
  .InvestmentsBlog_wrapper__1xY-4 {
    padding: 50px 10px 30px 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
  }
  .InvestmentsBlog_leftWrapper__2DlsR {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
  }
  .InvestmentsBlog_leftContainer__abyeW {
    display: none;
  }
  .InvestmentsBlog_rightContainer__2HgyS {
    display: none;
  }
  .InvestmentsBlog_middleContainer__3cXE- {
    width: 64%;
  }
  .InvestmentsBlog_goBackMob__2axoX {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 25px;
    cursor: pointer;
  }
  .InvestmentsBlog_goBackMob__2axoX p {
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.04em;
    color: var(--tertiary-color);
    font-weight: bold;
    margin: 0px;
    margin-left: 5px;
  }
  .InvestmentsBlog_goBackMob__2axoX svg {
    width: 24px;
  }
  .InvestmentsBlog_subscribeMob__4dZgt {
    margin: 40px 0px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
  }
}
@media screen and (max-width: 768px) {
  .InvestmentsBlog_middleContainer__3cXE- {
    width: 100%;
    max-width: 600px;
  }
  .InvestmentsBlog_wrapper__1xY-4 {
    padding: 15px 10px 0px 10px;
  }
}
@media screen and (max-width: 480px) {
  .InvestmentsBlog_middleContainer__3cXE- h1 {
    font-size: 36px;
  }
}

.Loader_container__3LFDG {
  height: 150px;
  width: 150px;
  position: fixed;
  margin: auto;
  left: 0;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  z-index: 999;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.Loader_primaryContainer__3i3Wl {
  background-color: var(--tertiary-color);
}
.Loader_secondaryContainer__r9NMN {
  background-color: var(--secondary-color);
}
.Loader_loader__1j2Bs {
  border-radius: 50%;
  width: 110px;
  height: 110px;
  -webkit-animation: Loader_spin__3E4o4 2s linear infinite;
          animation: Loader_spin__3E4o4 2s linear infinite;
}
.Loader_primaryContainer__3i3Wl .Loader_loader__1j2Bs {
  border: 10px solid var(--secondary-color);
  border-top: 10px solid var(--tertiary-color);
}
.Loader_secondaryContainer__r9NMN .Loader_loader__1j2Bs {
  border: 10px solid var(--tertiary-color);
  border-top: 10px solid var(--secondary-color);
}

@-webkit-keyframes Loader_spin__3E4o4 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes Loader_spin__3E4o4 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@media screen and (max-width: 480px) {
  .Loader_container__3LFDG {
    height: 100px;
    width: 100px;
  }
  .Loader_loader__1j2Bs {
    width: 70px;
    height: 70px;
  }
  .Loader_primaryContainer__3i3Wl .Loader_loader__1j2Bs {
    border: 6px solid var(--secondary-color);
    border-top: 6px solid var(--tertiary-color);
  }
  .Loader_secondaryContainer__r9NMN .Loader_loader__1j2Bs {
    border: 6px solid var(--tertiary-color);
    border-top: 6px solid var(--secondary-color);
  }
}

.Song_container__3vFBa {
  z-index: 9;
  width: 380px;
  height: 36px;
  border-top: 1px solid #2b2a35;
  margin-bottom: 15px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  position: relative;
  cursor: pointer;
}
.Song_placeHolder__1lP2f {
  width: 100%;
  position: absolute;
  height: 36px;
  bottom: -8px;
  z-index: 980;
}
.Song_container__3vFBa audio {
  visibility: hidden;
  position: absolute;
}
.Song_container__3vFBa p {
  margin: 0px !important;
  position: absolute;
}
.Song_playIcon__2n8bI {
  position: absolute;
  left: 0px;
  top: 16px;
  width: 20px;
  z-index: 982;
}
.Song_pauseIcon__3jZRs {
  position: absolute;
  left: 0px;
  top: 16px;
  width: 20px;
  z-index: 982;
}
.Song_index__PL9rF {
  font-size: 15px;
  line-height: 18px;
  color: #585766;
  left: 0px;
  z-index: 99;
}
.Song_container__3vFBa h6 {
  font-size: 15px;
  line-height: 18px;
  color: var(--tertiary-color);
  left: 45px;
  position: absolute;
  width: 85%;
}
.Song_length__3sn9s {
  font-size: 15px;
  line-height: 18px;
  color: #585766;
  right: 3px;
}

@media screen and (max-width: 480px) {
  .Song_container__3vFBa {
    width: 100%;
  }
}
.Music_container__ZoJPD {
  height: 100%;
  min-height: calc(100vh - 143px);
  background-color: var(--secondary-color);
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}
.Music_wrapper__1JETK {
  width: 100%;
  max-width: 1250px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 50px 0px 40px 40px;
}
.Music_textContainer__FioLZ {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
}
.Music_textContainer__FioLZ h1 {
  font-size: 60px;
  line-height: 75px;
  color: var(--tertiary-color);
  letter-spacing: -0.04em;
  margin-bottom: 20px;
}
.Music_textContainer__FioLZ p {
  font-size: 30px;
  line-height: 35px;
  letter-spacing: -0.04em;
  color: var(--tertiary-color);
  margin-bottom: 40px;
}
.Music_musicCategories__1uc0Z {
  display: -webkit-flex;
  display: flex;
}
.Music_category__22K5a {
  width: 50%;
}
.Music_subTextContainer__u9Ic- h5 {
  font-size: 18px;
  line-height: 20px;
  letter-spacing: -0.04em;
  color: var(--tertiary-color);
  margin-bottom: 8px;
}
.Music_subTextContainer__u9Ic- p {
  width: 408px;
  font-size: 15px;
  line-height: 18px;
  color: var(--tertiary-color);
  height: 35px;
  margin-bottom: 22px;
}
.Music_musicContainer__1lbDw {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.Music_musicContainer__1lbDw p {
  font-size: 15px;
  line-height: 18px;
  color: #585766;
  margin-bottom: 10px;
}

@media screen and (max-width: 992px) {
  .Music_wrapper__1JETK {
    padding: 50px 10px 0px 10px;
  }
  .Music_textContainer__FioLZ h1 {
    font-size: 50px;
    margin-bottom: 10px;
  }
  .Music_textContainer__FioLZ p {
    font-size: 26px;
  }
  .Music_musicCategories__1uc0Z {
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }
  .Music_subTextContainer__u9Ic- p {
    width: 90%;
  }
}
@media screen and (max-width: 780px) {
  .Music_musicCategories__1uc0Z {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .Music_category__22K5a {
    width: 100%;
    margin-bottom: 40px;
  }
  .Music_subTextContainer__u9Ic- h5 {
    margin-bottom: 10px;
  }
  .Music_subTextContainer__u9Ic- p {
    max-width: 408px;
    margin-bottom: 14px;
  }
}
@media screen and (max-width: 480px) {
  .Music_wrapper__1JETK {
    padding: 25px 10px 0px 10px;
  }
  .Music_textContainer__FioLZ h1 {
    font-size: 35px;
    margin-bottom: 0px;
  }
  .Music_textContainer__FioLZ p {
    font-size: 18px;
    margin-bottom: 30px;
    line-height: 30px;
  }
  .Music_subTextContainer__u9Ic- p {
    margin-bottom: 25px;
    font-size: 14px;
  }
  .Music_subTextContainer__u9Ic- h5 {
    font-size: 16px;
    margin-bottom: 4px;
  }
}
@media screen and (max-width: 372px) {
  .Music_container__ZoJPD {
    min-height: calc(100vh);
  }  
}
.BackDrop_container__1a18d {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: black;
  opacity: 0.2;
  z-index: 100;
}
.BackDrop_hide__216kR {
  display: none;
}

.Card_container__3BCzV {
  width: 339px;
  height: 159px;
  background: #dcdcdc;
  box-shadow: inset -6px -3px 6px #ffffff,
    inset 4px 2px 6px rgba(72, 100, 125, 0.3);
  border-radius: 10px;
  margin-bottom: 20px;
  margin-right: 15px;
  position: relative;
  padding: 12px 15px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  cursor: pointer;
}
.Card_titleContainer__2WkR7 {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.Card_titleContainer__2WkR7 svg {
  margin-top: 5px;
}
.Card_container__3BCzV h5 {
  width: auto;
  max-width: 250px;
  font-size: 16px;
  line-height: 26px;
  color: var(--tertiary-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.Card_container__3BCzV p {
  width: 307px;
  font-size: 15px;
  line-height: 22px;
  font-weight: 400;
  color: var(--tertiary-color);
}
.Card_more__2xP2B {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.Card_more__2xP2B p {
  font-size: 15px;
  line-height: 22px;
  color: var(--tertiary-color);
  font-weight: bold;
  width: auto;
}
.Card_more__2xP2B small {
  width: auto;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  color: var(--tertiary-color);
}

@media screen and (max-width: 480px) {
  .Card_container__3BCzV {
    width: 100%;
    max-width: 380px;
  }
}
@media screen and (max-width: 372px) {
  .Card_container__3BCzV h5 {
    width: auto;
    font-size: 16px;
    line-height: 26px;
    color: var(--tertiary-color);
  }
  .Card_container__3BCzV p {
    width: auto;
    font-size: 14px;
    line-height: 22px;
    color: var(--tertiary-color);
  }
}

.PasswordCard_container__Rd65R {
  background: #dcdcdc;
  border-radius: 10px;
  z-index: 102;
  width: 321.97px;
  height: 146px;
  position: absolute;
  right: 0;
  left: 0;
  margin: auto;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  top: 50%;
  padding: 8px 12px;
  cursor: default;
}
.PasswordCard_titleContainer__2uN-W {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-bottom: 8px;
}
.PasswordCard_titleContainer__2uN-W h3 {
  font-family: Noto Sans JP;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #2b2a35;
  width: auto;
  max-width: 250px;
}
.PasswordCard_inputContainer__26s-J {
  margin-bottom: 10px;
}
.PasswordCard_inputContainer__26s-J label {
  font-family: Noto Sans JP;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 10px;
  color: #2b2a35;
}
.PasswordCard_inputWrapper__2XTCT {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.PasswordCard_inputWrapper__2XTCT input {
  width: 203px;
  height: 29px;
  background: #ffffff;
  border-radius: 4px;
  border: none;
  outline: none;
  padding: 0px 5px;
}
.PasswordCard_inputWrapper__2XTCT button {
  width: 81px;
  height: 29px;
  background: #2b2a35;
  border-radius: 4px;
  font-family: Noto Sans JP;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 10px;
  color: #ffffff;
  border: none;
  cursor: pointer;
}
.PasswordCard_inputWrapper__2XTCT button:hover {
  opacity: 0.9;
}
.PasswordCard_container__Rd65R p {
  font-family: Noto Sans JP;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  color: #2b2a35;
}
.PasswordCard_container__Rd65R .PasswordCard_error__1QbpL {
  font-family: Noto Sans JP;
  font-style: normal;
  font-weight: 500;
  font-size: 8px;
  line-height: 10px;
  color: red;
  margin-top: -7px;
  opacity: 0.8;
}

.DesignPortfolio_container__5UwaS {
  height: 100%;
  min-height: calc(100vh - 143px);
  background-color: var(--secondary-color);
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}
.DesignPortfolio_wrapper__oYyln {
  width: 100%;
  max-width: 1250px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 50px 0px 40px 40px;
}
.DesignPortfolio_textContainer__1Rx3O {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
}
.DesignPortfolio_textContainer__1Rx3O h1 {
  font-size: 60px;
  line-height: 75px;
  color: var(--tertiary-color);
  letter-spacing: -0.04em;
  margin-bottom: 15px;
}
.DesignPortfolio_quote__SgoNQ {
  width: 1016px;
  margin-bottom: 20px;
}
.DesignPortfolio_quote__SgoNQ blockquote p {
  font-style: italic;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  margin-right: 12px;
}
.DesignPortfolio_portfolioProjects__hRRFD {
  width: 1080px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}
.DesignPortfolio_portfolioProjects__hRRFD a {
  text-decoration: none;
  color: unset;
}
@media screen and (max-width: 1100px) {
  .DesignPortfolio_wrapper__oYyln {
    padding: 50px 10px 0px 10px;
  }
  .DesignPortfolio_portfolioProjects__hRRFD {
    width: 100%;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
  }
  .DesignPortfolio_quote__SgoNQ {
    width: 90%;
  }
}
@media screen and (max-width: 768px) {
  .DesignPortfolio_portfolioProjects__hRRFD {
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
  }
}
@media screen and (max-width: 480px) {
  .DesignPortfolio_wrapper__oYyln {
    padding: 15px 10px 0px 10px;
  }
  .DesignPortfolio_portfolioProjects__hRRFD {
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: flex-start;
            align-items: flex-start;
  }
  .DesignPortfolio_textContainer__1Rx3O h1 {
    font-size: 35px;
    margin-bottom: 5px;
    line-height: 60px;
  }
  .DesignPortfolio_quote__SgoNQ {
    width: 95%;
    margin-bottom: 20px;
  }
  .DesignPortfolio_quote__SgoNQ blockquote p {
    font-size: 16px;
    line-height: 26px;
  }
}
@media screen and (max-width: 372px) {
  .DesignPortfolio_container__5UwaS {
    min-height: calc(100vh);
  }  
}
.WritingDetail_container__2-kGX {
  height: 100%;
  min-height: calc(100vh);
  background-color: var(--secondary-color);
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}
.WritingDetail_wrapper__1xPVL {
  width: 100%;
  max-width: 1250px;
  display: -webkit-flex;
  display: flex;
  padding: 30px 0px 40px 0px;
}
.WritingDetail_leftWrapper__Z1Vhd {
  width: 75%;
  display: -webkit-flex;
  display: flex;
}
.WritingDetail_leftContainer__1GLlH {
  width: 36%;
  position: relative;
}
.WritingDetail_goBack__2wEW6 {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 130px;
  margin-top: 10px;
  cursor: pointer;
}
.WritingDetail_goBack__2wEW6 p {
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.04em;
  color: var(--tertiary-color);
  font-weight: bold;
  margin-left: 5px;
}
.WritingDetail_leftContainer__1GLlH ul {
  margin: 0px;
  padding: 0px;
  position: fixed;
  top: 260px;
}
.WritingDetail_leftContainer__1GLlH ul li {
  margin: 0px;
  padding: 0px;
  list-style: none;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.04em;
  color: var(--tertiary-color);
  margin-bottom: 8px;
}
.WritingDetail_leftContainer__1GLlH ul li a {
  color: unset;
  text-decoration: none;
}
.WritingDetail_subHeading__1G03H {
  padding-left: 20px !important;
}

.WritingDetail_middleContainer__3rhL8 {
  width: 64%;
}
.WritingDetail_middleContainer__3rhL8 h1 {
  font-size: 40px;
  line-height: 44px;
  letter-spacing: -0.04em;
  color: var(--tertiary-color);
  margin-bottom: 25px;
  padding: 10px 0px;
}
.WritingDetail_middleContainer__3rhL8 h2 {
  margin: 18px 0px;
  padding: 10px 0px;
  font-size: 30px;
  line-height: 40px;
  letter-spacing: -0.04em;
  color: var(--tertiary-color);
}
.WritingDetail_middleContainer__3rhL8 h3 {
  margin-bottom: 8px;
  padding: 10px 0px;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.04em;
  color: var(--tertiary-color);
}
.WritingDetail_middleContainer__3rhL8 p {
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 18px;
}
.WritingDetail_middleContainer__3rhL8 p strong {
  font-size: 18px;
  line-height: 18px;
  letter-spacing: -0.04em;
  color: #000000;
}
.WritingDetail_middleContainer__3rhL8 img {
  width: 100%;
}
.WritingDetail_middleContainer__3rhL8 blockquote p {
  font-style: italic;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
}
.WritingDetail_middleContainer__3rhL8 ul {
  padding-left: 20px;
}
.WritingDetail_middleContainer__3rhL8 ul li {
  font-size: 16px;
  line-height: 25px;
  letter-spacing: -0.04em;
  margin-bottom: 10px;
  color: var(--tertiary-color);
}
.WritingDetail_goBackMob__F9WnQ {
  display: none;
}
.WritingDetail_subscribeMob__3dYV- {
  display: none;
}

@media screen and (max-width: 992px) {
  .WritingDetail_wrapper__1xPVL {
    padding: 50px 10px 30px 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
  }
  .WritingDetail_leftWrapper__Z1Vhd {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
  }
  .WritingDetail_leftContainer__1GLlH {
    display: none;
  }
  .WritingDetail_rightContainer__HdV_6 {
    display: none;
  }
  .WritingDetail_middleContainer__3rhL8 {
    width: 64%;
  }
  .WritingDetail_goBackMob__F9WnQ {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 25px;
    cursor: pointer;
  }
  .WritingDetail_goBackMob__F9WnQ p {
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.04em;
    color: var(--tertiary-color);
    font-weight: bold;
    margin: 0px;
    margin-left: 5px;
  }
  .WritingDetail_goBackMob__F9WnQ svg {
    width: 24px;
  }
  .WritingDetail_subscribeMob__3dYV- {
    margin: 40px 0px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
  }
}
@media screen and (max-width: 768px) {
  .WritingDetail_middleContainer__3rhL8 {
    width: 100%;
    max-width: 600px;
  }
  .WritingDetail_wrapper__1xPVL {
    padding: 15px 10px 0px 10px;
  }
}
@media screen and (max-width: 480px) {
  .WritingDetail_middleContainer__3rhL8 h1 {
    font-size: 36px;
  }
}

.DesignPortfolioBlog_container__1mfub {
  height: 100%;
  min-height: calc(100vh);
  background-color: var(--tertiary-color);
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}
.DesignPortfolioBlog_wrapper__1Ki0Q {
  width: 100%;
  max-width: 1250px;
  display: -webkit-flex;
  display: flex;
  padding: 30px 0px 40px 0px;
}
.DesignPortfolioBlog_leftWrapper__1mmw3 {
  width: 75%;
  display: -webkit-flex;
  display: flex;
}
.DesignPortfolioBlog_leftContainer__2Untt {
  width: 36%;
  position: relative;
}
.DesignPortfolioBlog_goBack__w1_qW {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 130px;
  cursor: pointer;
  margin-top: 10px;
}
.DesignPortfolioBlog_goBack__w1_qW svg path {
  fill: #ffffff;
}
.DesignPortfolioBlog_goBack__w1_qW p {
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.04em;
  color: #ffffff;
  font-weight: bold;
  margin-left: 5px;
}
.DesignPortfolioBlog_leftContainer__2Untt ul {
  margin: 0px;
  padding: 0px;
  position: fixed;
  top: 260px;
}
.DesignPortfolioBlog_leftContainer__2Untt ul li {
  margin: 0px;
  padding: 0px;
  list-style: none;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.04em;
  color: #ffffff;
  margin-bottom: 8px;
}
.DesignPortfolioBlog_leftContainer__2Untt ul li a {
  color: unset;
  text-decoration: none;
}
.DesignPortfolioBlog_subHeading__3towm {
  padding-left: 20px !important;
}

.DesignPortfolioBlog_middleContainer__yMUBb {
  width: 64%;
}
.DesignPortfolioBlog_middleContainer__yMUBb h1 {
  font-size: 40px;
  line-height: 44px;
  letter-spacing: -0.04em;
  color: #ffffff;
  margin-bottom: 15px;
  padding: 10px 0px;
}
.DesignPortfolioBlog_middleContainer__yMUBb h2 {
  margin: 8px 0px 8px 0px;
  font-size: 30px;
  line-height: 40px;
  letter-spacing: -0.04em;
  color: #ffffff;
  padding: 10px 0px;
}
.DesignPortfolioBlog_middleContainer__yMUBb h3 {
  margin-bottom: 8px;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.04em;
  color: #ffffff;
  padding: 10px 0px;
}
.DesignPortfolioBlog_middleContainer__yMUBb p {
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  margin-bottom: 18px;
}
.DesignPortfolioBlog_middleContainer__yMUBb p strong {
  font-size: 18px;
  line-height: 18px;
  letter-spacing: -0.04em;
  color: #ffffff;
}
.DesignPortfolioBlog_middleContainer__yMUBb img {
  width: 100%;
  margin-top: 10px;
}
.DesignPortfolioBlog_middleContainer__yMUBb blockquote p {
  font-style: italic;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
}
.DesignPortfolioBlog_middleContainer__yMUBb ul {
  padding-left: 20px;
  margin-bottom: 18px;
}
.DesignPortfolioBlog_middleContainer__yMUBb ul li {
  font-size: 16px;
  line-height: 25px;
  letter-spacing: -0.04em;
  margin-bottom: 10px;
  color: #ffffff;
}
.DesignPortfolioBlog_middleContainer__yMUBb ol {
  padding-left: 20px;
  margin-bottom: 18px;
}
.DesignPortfolioBlog_middleContainer__yMUBb ol li {
  font-size: 16px;
  line-height: 25px;
  letter-spacing: -0.04em;
  margin-bottom: 10px;
  color: #ffffff;
}
.DesignPortfolioBlog_middleContainer__yMUBb a {
  color: #ffffff;
}
.DesignPortfolioBlog_goBackMob__3rl3u {
  display: none;
}
.DesignPortfolioBlog_subscribeMob__3GrYD {
  display: none;
}

@media screen and (max-width: 992px) {
  .DesignPortfolioBlog_wrapper__1Ki0Q {
    padding: 50px 10px 30px 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
  }
  .DesignPortfolioBlog_leftWrapper__1mmw3 {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
  }
  .DesignPortfolioBlog_leftContainer__2Untt {
    display: none;
  }
  .DesignPortfolioBlog_rightContainer__1SSIH {
    display: none;
  }
  .DesignPortfolioBlog_middleContainer__yMUBb {
    width: 64%;
  }
  .DesignPortfolioBlog_goBackMob__3rl3u {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 25px;
    cursor: pointer;
  }
  .DesignPortfolioBlog_goBackMob__3rl3u p {
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.04em;
    color: #ffffff;
    font-weight: bold;
    margin: 0px;
    margin-left: 5px;
  }
  .DesignPortfolioBlog_goBackMob__3rl3u svg {
    width: 24px;
  }
  .DesignPortfolioBlog_goBackMob__3rl3u svg path {
    fill: #ffffff;
  }
  .DesignPortfolioBlog_subscribeMob__3GrYD {
    margin: 40px 0px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
  }
}
@media screen and (max-width: 768px) {
  .DesignPortfolioBlog_middleContainer__yMUBb {
    width: 100%;
    max-width: 600px;
  }
  .DesignPortfolioBlog_wrapper__1Ki0Q {
    padding: 15px 10px 0px 10px;
  }
}
@media screen and (max-width: 480px) {
  .DesignPortfolioBlog_middleContainer__yMUBb h1 {
    font-size: 36px;
  }
}

