.mainFooter {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.secondaryFooter {
  background-color: var(--tertiary-color);
}
.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px 0px;
  max-width: 1250px;
}
.logoContainer {
  display: flex;
  align-items: center;
}
.logoContainer img {
  width: 40px;
  height: 40px;
  border-radius: 100%;
}
.logoContainer p {
  font-size: 14px;
  margin-left: 10px;
  text-transform: capitalize;
}
.primaryFooter .logoContainer p {
  color: var(--tertiary-color);
}
.secondaryFooter .logoContainer p {
  color: #ffffff;
}
.logoContainer p span {
  font-weight: bold;
  margin-right: 5px;
}
.socialContainer {
  display: flex;
  align-items: center;
}
.twitterIcon {
  cursor: pointer;
  width: 24px;
  height: 20px;
  margin-left: 20px;
}
.linkedinIcon {
  cursor: pointer;
  width: 24px;
  height: 20px;
  margin-left: 20px;
}
.secondaryFooter .twitterIcon path {
  fill: #ffffff;
}
.secondaryFooter .linkedinIcon path {
  fill: #ffffff;
}
.circle {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -20px;
  left: 0;
  right: 0;
  margin: auto;
}
.circle svg {
  margin-bottom: 5px;
}

@media screen and (max-width: 992px) {
  .container {
    padding: 10px 10px;
  }
}
@media screen and (max-width: 768px) {
  .container {
    flex-direction: column;
    padding: 30px 10px;
  }
  .logoContainer {
    order: 2;
  }
  .socialContainer {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 480px) {
  .container {
    padding: 20px 10px;
  }
  .logoContainer {
    margin-top: 5px;
  }
  .logoContainer img {
    width: 28px;
    height: 28px;
  }
  .logoContainer p {
    font-size: 15px;
    margin-left: 5px;
  }
  .socialContainer {
    display: flex;
    align-items: center;
  }
  .twitterIcon {
    width: 22px;
    height: 22px;
    margin-left: 25px;
  }
  .linkedinIcon {
    width: 22px;
    height: 22px;
    margin-left: 25px;
  }
  .circle {
    width: 45px;
    height: 45px;
    top: -27px;
  }
  .circle svg {
    width: 22.5px;
    height: 22.5px;
  }
}