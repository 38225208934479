.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-color);
}
.wrapper {
  width: 100%;
  max-width: 1250px;
  padding: 100px 0px 0px 0px;
}
.textContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.textContainer h1 {
  text-align: center;
  font-size: 75px;
  color: #ffffff;
  line-height: 100px;
  margin-bottom: 30px;
  width: 900px;
}
.textContainer p {
  text-align: center;
  color: #ffffff;
  font-size: 20px;
  font-weight: 300;
  line-height: 40px;
  margin-top: 10px;
  width: 905px;
}
.details {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 220px;
}
.socialContainer {
  display: flex;
  align-items: center;
}
.socialContainer a {
  text-decoration: none;
  color: unset;
}
.twitterIcon {
  width: 24px;
  height: 20px;
  margin-left: 20px;
  cursor: pointer;
}
.twitterIcon path {
  fill: #ffffff;
}
.linkedinIcon {
  cursor: pointer;
  width: 24px;
  height: 20px;
  margin-left: 20px;
}
.linkedinIcon path {
  fill: #ffffff;
}
.locationContainer {
  display: flex;
  align-items: center;
}
.locationContainer div {
  display: flex;
  align-items: center;
  margin-left: 30px;
}
.globeIcon {
  width: 25px;
  height: 25px;
}
.locationContainer p {
  color: #ffffff;
  margin-left: 7px;
  font-size: 17px;
  line-height: 24.62px;
}
.line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.line span {
  width: 47%;
  background-color: white;
  height: 1px;
}
.circle {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
}
.circle svg {
  margin-top: 4px;
}

@media screen and (max-width: 992px) {
  .textContainer h1 {
    font-size: 60px;
    line-height: 80px;
    margin-bottom: 15px;
    width: 90%;
  }
  .textContainer p {
    font-size: 16px;
    line-height: 30px;
    margin-top: 10px;
    width: 750px;
  }
  .details {
    padding: 0px 10px;
  }
  .twitterIcon {
    width: 18px;
    height: 18px;
    margin-left: 10px;
  }
  .linkedinIcon {
    width: 18px;
    height: 18px;
    margin-left: 10px;
  }
  .locationContainer div {
    margin-left: 15px;
  }
  .locationIcon {
    width: 18px;
    height: 18px;
  }
  .globeIcon {
    width: 18px;
    height: 18px;
  }
  .locationContainer p {
    margin-left: 5px;
    font-size: 14px;
  }
}
@media screen and (max-width: 768px) {
  .textContainer h1 {
    font-size: 40px;
    line-height: 60px;
  }
  .textContainer p {
    font-size: 16px;
    line-height: 30px;
    margin-top: 0px;
    width: 90%;
  }
  .details {
    flex-direction: column;
    justify-content: center;
  }
  .socialContainer {
    margin-bottom: 15px;
  }
  .twitterIcon {
    margin-left: 20px;
  }
  .linkedinIcon {
    margin-left: 20px;
  }
  .locationContainer div {
    margin-left: 20px;
  }
  .line {
    margin-top: 20px;
  }
  .line span {
    width: 47%;
  }
  .circle {
    width: 30px;
    height: 30px;
  }
  .circle svg {
    margin-top: 4px;
  }
  .triangle {
    width: 20px;
    height: 20px;
  }
}
@media screen and (max-width: 480px) {
  .wrapper {
    padding: 70px 0px 0px 0px;
  }
  .textContainer h1 {
    font-size: 38px;
    line-height: 48px;
    max-width: 360px;
    width: 100%;
    margin-bottom: 35px;
  }
  .textContainer p {
    font-size: 18px;
    line-height: 30px;
    width: 100%;
    max-width: 360px;
  }
  .details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 120px;
    padding: 0px 10px;
  }
  .socialContainer {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
  }
  .twitterIcon {
    width: 22px;
    height: 22px;
    margin-left: 22px;
  }
  .linkedinIcon {
    width: 22px;
    height: 22px;
    margin-left: 22px;
  }
  .locationContainer {
    margin-bottom: 2px;
  }
  .locationContainer div {
    margin-left: 10px;
  }
  .locationIcon {
    width: 22px;
    height: 22px;
  }
  .globeIcon {
    width: 22px;
    height: 22px;
  }
  .locationContainer p {
    margin-left: 5px;
    font-size: 12px;
    line-height: 24.62px;
  }
  .line {
    margin-top: 10px;
  }
  .line span {
    width: 50%;
  }
  .circle {
    width: 25px;
    height: 25px;
    display: none;
  }
  .circle svg {
    margin-top: 3px;
  }
  .triangle {
    width: 15px;
    height: 15px;
  }
}
@media screen and (max-width: 372px) {
  .textContainer h1 {
    font-size: 34px;
    line-height: 46px;
    max-width: 330px;
  }
  .textContainer p {
    font-size: 16px;
    line-height: 30px;
    max-width: 320px;
  }
  .details {
    margin-top: 120px;
    padding: 0px 10px;
  }
  .twitterIcon {
    width: 20px;
    height: 20px;
    margin-left: 20px;
  }
  .linkedinIcon {
    width: 20px;
    height: 20px;
    margin-left: 20px;
  }
  .locationContainer div {
    margin-left: 12px;
  }
  .locationIcon {
    width: 20px;
    height: 20px;
  }
  .globeIcon {
    width: 20px;
    height: 20px;
  }
  .locationContainer p {
    margin-left: 5px;
    font-size: 10px;
  }
}
