.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  padding: 15px 25px;
  border-radius: 10px;
  margin-bottom: 20px;
  position: relative;
}
.container p {
  z-index: 999;
}
.container p>strong {
  line-height: 0;
  font-size: 40px;
}
.primaryContainer {
  background: #0f0864;
  align-self: flex-start;
}
.primaryContainer p {
  font-size: 20px;
  line-height: 20px;
  color: #ffffff;
  font-weight: 700;
}
.secondaryContainer {
  background: #ffffff;
  border-radius: 10px;
  align-self: flex-end;
  max-width: 847px;
}
.secondaryContainer p {
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;
  color: var(--tertiary-color);
}
.left {
  position: absolute;
  left: -8px;
  top: 2px;
}
.right {
  position: absolute;
  right: -12px;
  top: 4px;
}
.typingBlock {
  width: 82px;
  height: 41px;
}
.typingBlock p {
  font-weight: bold;
  font-size: 30px;
  color: var(--tertiary-color);
}

@media screen and (max-width: 992px) {
  .container {
    padding: 15px 20px;
  }
  .primaryContainer {
    width: auto;
  }
  .primaryContainer p {
    font-size: 16px;
    line-height: 18px;
  }
  .secondaryContainer p {
    font-size: 14px;
    line-height: 23px;
  }
  .secondaryContainer {
    width: auto;
  }
}

@media screen and (max-width: 480px) {
  .container {
    padding: 8px 12px;
    border-radius: 5px;
    margin-bottom: 15px;
  }
  .primaryContainer p {
    font-size: 14px;
    line-height: 20px;
  }
  .secondaryContainer {
    border-radius: 5px;
  }
  .secondaryContainer p {
    font-size: 14px;
    line-height: 22px;
  }
  .typingBlock {
    width: 72px;
    height: 38px;
  }
  .typingBlock p {
    font-weight: bold;
    font-size: 24px;
    color: var(--tertiary-color);
  }
}
