.container {
  height: 100%;
  min-height: calc(100vh - 143px);
  background-color: var(--secondary-color);
  display: flex;
  justify-content: center;
}
.wrapper {
  width: 100%;
  max-width: 1250px;
  display: flex;
  flex-direction: column;
  padding: 50px 0px 40px 40px;
}
.textContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.textContainer h1 {
  font-size: 60px;
  line-height: 75px;
  color: var(--tertiary-color);
  letter-spacing: -0.04em;
  margin-bottom: 15px;
}
.quote {
  width: 1016px;
  margin-bottom: 20px;
}
.quote blockquote p {
  font-style: italic;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  margin-right: 12px;
}
.portfolioProjects {
  width: 1080px;
  display: flex;
  flex-wrap: wrap;
}
.portfolioProjects a {
  text-decoration: none;
  color: unset;
}
@media screen and (max-width: 1100px) {
  .wrapper {
    padding: 50px 10px 0px 10px;
  }
  .portfolioProjects {
    width: 100%;
    justify-content: flex-start;
  }
  .quote {
    width: 90%;
  }
}
@media screen and (max-width: 768px) {
  .portfolioProjects {
    justify-content: flex-start;
  }
}
@media screen and (max-width: 480px) {
  .wrapper {
    padding: 15px 10px 0px 10px;
  }
  .portfolioProjects {
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
  }
  .textContainer h1 {
    font-size: 35px;
    margin-bottom: 5px;
    line-height: 60px;
  }
  .quote {
    width: 95%;
    margin-bottom: 20px;
  }
  .quote blockquote p {
    font-size: 16px;
    line-height: 26px;
  }
}
@media screen and (max-width: 372px) {
  .container {
    min-height: calc(100vh);
  }  
}