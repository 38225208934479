.container {
  width: 339px;
  height: 159px;
  background: #dcdcdc;
  box-shadow: inset -6px -3px 6px #ffffff,
    inset 4px 2px 6px rgba(72, 100, 125, 0.3);
  border-radius: 10px;
  margin-bottom: 20px;
  margin-right: 15px;
  position: relative;
  padding: 12px 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}
.titleContainer {
  display: flex;
  justify-content: space-between;
}
.titleContainer svg {
  margin-top: 5px;
}
.container h5 {
  width: auto;
  max-width: 250px;
  font-size: 16px;
  line-height: 26px;
  color: var(--tertiary-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.container p {
  width: 307px;
  font-size: 15px;
  line-height: 22px;
  font-weight: 400;
  color: var(--tertiary-color);
}
.more {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.more p {
  font-size: 15px;
  line-height: 22px;
  color: var(--tertiary-color);
  font-weight: bold;
  width: auto;
}
.more small {
  width: auto;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  color: var(--tertiary-color);
}

@media screen and (max-width: 480px) {
  .container {
    width: 100%;
    max-width: 380px;
  }
}
@media screen and (max-width: 372px) {
  .container h5 {
    width: auto;
    font-size: 16px;
    line-height: 26px;
    color: var(--tertiary-color);
  }
  .container p {
    width: auto;
    font-size: 14px;
    line-height: 22px;
    color: var(--tertiary-color);
  }
}
