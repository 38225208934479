@import url('https://fonts.googleapis.com/css2?family=Sacramento&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;500;700&display=swap');
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Noto Sans JP", sans-serif;
}
html, body {
  scroll-behavior: smooth;
}
:root {
  --primary-color: #1f32fb;
  --secondary-color: #f4f4f4;
  --tertiary-color: #2B2A35;
}
