.container {
  z-index: 9;
  width: 380px;
  height: 36px;
  border-top: 1px solid #2b2a35;
  margin-bottom: 15px;
  display: flex;
  align-items: flex-end;
  position: relative;
  cursor: pointer;
}
.placeHolder {
  width: 100%;
  position: absolute;
  height: 36px;
  bottom: -8px;
  z-index: 980;
}
.container audio {
  visibility: hidden;
  position: absolute;
}
.container p {
  margin: 0px !important;
  position: absolute;
}
.playIcon {
  position: absolute;
  left: 0px;
  top: 16px;
  width: 20px;
  z-index: 982;
}
.pauseIcon {
  position: absolute;
  left: 0px;
  top: 16px;
  width: 20px;
  z-index: 982;
}
.index {
  font-size: 15px;
  line-height: 18px;
  color: #585766;
  left: 0px;
  z-index: 99;
}
.container h6 {
  font-size: 15px;
  line-height: 18px;
  color: var(--tertiary-color);
  left: 45px;
  position: absolute;
  width: 85%;
}
.length {
  font-size: 15px;
  line-height: 18px;
  color: #585766;
  right: 3px;
}

@media screen and (max-width: 480px) {
  .container {
    width: 100%;
  }
}