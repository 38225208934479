.container {
  border: 1px solid white;
  position: fixed;
  width: 280px;
  top: 0px;
  height: 100vh;
  background-color: #ffffff;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 400ms;
}
.showHeader {
  right: 0px;
}
.hideHeader {
  right: -300px;
}
.container nav {
  display: block;
}
.container nav ul {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.container nav ul a {
  color: unset;
  text-decoration: none;
  margin-bottom: 40px;
}
.container nav ul a li {
  color: var(--tertiary-color);
}
.times {
  position: absolute;
  top: 20px;
  right: 10px;
  color: var(--tertiary-color);
}
@media screen and (min-width: 993px) {
  .container {
    display: none;
  }
}
