.container {
  height: 100%;
  min-height: calc(100vh - 143px);
  background-color: var(--secondary-color);
  display: flex;
  justify-content: center;
}
.wrapper {
  width: 100%;
  max-width: 1250px;
  display: flex;
  flex-direction: column;
  padding: 50px 0px 40px 40px;
}
.textContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.textContainer h1 {
  font-size: 60px;
  line-height: 75px;
  color: var(--tertiary-color);
  letter-spacing: -0.04em;
  margin-bottom: 20px;
}
.textContainer p {
  font-size: 30px;
  line-height: 35px;
  letter-spacing: -0.04em;
  color: var(--tertiary-color);
  margin-bottom: 40px;
}
.musicCategories {
  display: flex;
}
.category {
  width: 50%;
}
.subTextContainer h5 {
  font-size: 18px;
  line-height: 20px;
  letter-spacing: -0.04em;
  color: var(--tertiary-color);
  margin-bottom: 8px;
}
.subTextContainer p {
  width: 408px;
  font-size: 15px;
  line-height: 18px;
  color: var(--tertiary-color);
  height: 35px;
  margin-bottom: 22px;
}
.musicContainer {
  display: flex;
  flex-direction: column;
}
.musicContainer p {
  font-size: 15px;
  line-height: 18px;
  color: #585766;
  margin-bottom: 10px;
}

@media screen and (max-width: 992px) {
  .wrapper {
    padding: 50px 10px 0px 10px;
  }
  .textContainer h1 {
    font-size: 50px;
    margin-bottom: 10px;
  }
  .textContainer p {
    font-size: 26px;
  }
  .musicCategories {
    justify-content: space-between;
  }
  .subTextContainer p {
    width: 90%;
  }
}
@media screen and (max-width: 780px) {
  .musicCategories {
    flex-direction: column;
  }
  .category {
    width: 100%;
    margin-bottom: 40px;
  }
  .subTextContainer h5 {
    margin-bottom: 10px;
  }
  .subTextContainer p {
    max-width: 408px;
    margin-bottom: 14px;
  }
}
@media screen and (max-width: 480px) {
  .wrapper {
    padding: 25px 10px 0px 10px;
  }
  .textContainer h1 {
    font-size: 35px;
    margin-bottom: 0px;
  }
  .textContainer p {
    font-size: 18px;
    margin-bottom: 30px;
    line-height: 30px;
  }
  .subTextContainer p {
    margin-bottom: 25px;
    font-size: 14px;
  }
  .subTextContainer h5 {
    font-size: 16px;
    margin-bottom: 4px;
  }
}
@media screen and (max-width: 372px) {
  .container {
    min-height: calc(100vh);
  }  
}