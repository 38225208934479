.container {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100%;
  background-color: black;
  opacity: 0.6;
  z-index: 998;
}
.show {
    display: block;
}
.hide {
    display: none;
}