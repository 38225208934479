.container {
  height: 100%;
  min-height: calc(100vh);
  background-color: var(--tertiary-color);
  display: flex;
  justify-content: center;
}
.wrapper {
  width: 100%;
  max-width: 1250px;
  display: flex;
  padding: 30px 0px 40px 0px;
}
.leftWrapper {
  width: 75%;
  display: flex;
}
.leftContainer {
  width: 36%;
  position: relative;
}
.goBack {
  display: flex;
  align-items: center;
  margin-bottom: 130px;
  cursor: pointer;
  margin-top: 10px;
}
.goBack svg path {
  fill: #ffffff;
}
.goBack p {
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.04em;
  color: #ffffff;
  font-weight: bold;
  margin-left: 5px;
}
.leftContainer ul {
  margin: 0px;
  padding: 0px;
  position: fixed;
  top: 260px;
}
.leftContainer ul li {
  margin: 0px;
  padding: 0px;
  list-style: none;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.04em;
  color: #ffffff;
  margin-bottom: 8px;
}
.leftContainer ul li a {
  color: unset;
  text-decoration: none;
}
.subHeading {
  padding-left: 20px !important;
}

.middleContainer {
  width: 64%;
}
.middleContainer h1 {
  font-size: 40px;
  line-height: 44px;
  letter-spacing: -0.04em;
  color: #ffffff;
  margin-bottom: 15px;
  padding: 10px 0px;
}
.middleContainer h2 {
  margin: 8px 0px 8px 0px;
  font-size: 30px;
  line-height: 40px;
  letter-spacing: -0.04em;
  color: #ffffff;
  padding: 10px 0px;
}
.middleContainer h3 {
  margin-bottom: 8px;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.04em;
  color: #ffffff;
  padding: 10px 0px;
}
.middleContainer p {
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  margin-bottom: 18px;
}
.middleContainer p strong {
  font-size: 18px;
  line-height: 18px;
  letter-spacing: -0.04em;
  color: #ffffff;
}
.middleContainer img {
  width: 100%;
  margin-top: 10px;
}
.middleContainer blockquote p {
  font-style: italic;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
}
.middleContainer ul {
  padding-left: 20px;
  margin-bottom: 18px;
}
.middleContainer ul li {
  font-size: 16px;
  line-height: 25px;
  letter-spacing: -0.04em;
  margin-bottom: 10px;
  color: #ffffff;
}
.middleContainer ol {
  padding-left: 20px;
  margin-bottom: 18px;
}
.middleContainer ol li {
  font-size: 16px;
  line-height: 25px;
  letter-spacing: -0.04em;
  margin-bottom: 10px;
  color: #ffffff;
}
.middleContainer a {
  color: #ffffff;
}
.goBackMob {
  display: none;
}
.subscribeMob {
  display: none;
}

@media screen and (max-width: 992px) {
  .wrapper {
    padding: 50px 10px 30px 10px;
    display: flex;
    justify-content: center;
  }
  .leftWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .leftContainer {
    display: none;
  }
  .rightContainer {
    display: none;
  }
  .middleContainer {
    width: 64%;
  }
  .goBackMob {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    cursor: pointer;
  }
  .goBackMob p {
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.04em;
    color: #ffffff;
    font-weight: bold;
    margin: 0px;
    margin-left: 5px;
  }
  .goBackMob svg {
    width: 24px;
  }
  .goBackMob svg path {
    fill: #ffffff;
  }
  .subscribeMob {
    margin: 40px 0px;
    display: flex;
    justify-content: center;
  }
}
@media screen and (max-width: 768px) {
  .middleContainer {
    width: 100%;
    max-width: 600px;
  }
  .wrapper {
    padding: 15px 10px 0px 10px;
  }
}
@media screen and (max-width: 480px) {
  .middleContainer h1 {
    font-size: 36px;
  }
}
