.container {
  height: 150px;
  width: 150px;
  position: fixed;
  margin: auto;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.primaryContainer {
  background-color: var(--tertiary-color);
}
.secondaryContainer {
  background-color: var(--secondary-color);
}
.loader {
  border-radius: 50%;
  width: 110px;
  height: 110px;
  animation: spin 2s linear infinite;
}
.primaryContainer .loader {
  border: 10px solid var(--secondary-color);
  border-top: 10px solid var(--tertiary-color);
}
.secondaryContainer .loader {
  border: 10px solid var(--tertiary-color);
  border-top: 10px solid var(--secondary-color);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 480px) {
  .container {
    height: 100px;
    width: 100px;
  }
  .loader {
    width: 70px;
    height: 70px;
  }
  .primaryContainer .loader {
    border: 6px solid var(--secondary-color);
    border-top: 6px solid var(--tertiary-color);
  }
  .secondaryContainer .loader {
    border: 6px solid var(--tertiary-color);
    border-top: 6px solid var(--secondary-color);
  }
}
