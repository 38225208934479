.container {
  background: #dcdcdc;
  border-radius: 10px;
  z-index: 102;
  width: 321.97px;
  height: 146px;
  position: absolute;
  right: 0;
  left: 0;
  margin: auto;
  transform: translateY(-50%);
  top: 50%;
  padding: 8px 12px;
  cursor: default;
}
.titleContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}
.titleContainer h3 {
  font-family: Noto Sans JP;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #2b2a35;
  width: auto;
  max-width: 250px;
}
.inputContainer {
  margin-bottom: 10px;
}
.inputContainer label {
  font-family: Noto Sans JP;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 10px;
  color: #2b2a35;
}
.inputWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.inputWrapper input {
  width: 203px;
  height: 29px;
  background: #ffffff;
  border-radius: 4px;
  border: none;
  outline: none;
  padding: 0px 5px;
}
.inputWrapper button {
  width: 81px;
  height: 29px;
  background: #2b2a35;
  border-radius: 4px;
  font-family: Noto Sans JP;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 10px;
  color: #ffffff;
  border: none;
  cursor: pointer;
}
.inputWrapper button:hover {
  opacity: 0.9;
}
.container p {
  font-family: Noto Sans JP;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  color: #2b2a35;
}
.container .error {
  font-family: Noto Sans JP;
  font-style: normal;
  font-weight: 500;
  font-size: 8px;
  line-height: 10px;
  color: red;
  margin-top: -7px;
  opacity: 0.8;
}
