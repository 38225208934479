.container {
  display: flex;
  align-items: center;
  width: 30%;
  margin-bottom: 40px;
  cursor: pointer;
  min-width: 260px;
}
.container a {
  text-decoration: none;
  color: unset;
}
.container:hover {
  opacity: 0.8;
}
.container img {
  width: 44px;
  height: 44px;
}
.textContainer {
  margin-left: 12px;
  display: flex;
  flex-direction: column;
}
.textContainer h5 {
  font-size: 18px;
  line-height: 26px;
  color: var(--tertiary-color);
}
.textContainer small {
  font-size: 15px;
  line-height: 22px;
  color: var(--tertiary-color);
}
@media screen and (max-width: 480px) {
  .container {
    width: 100%;
  }
  .textContainer {
    margin-left: 10px;
  }
}