.container {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: black;
  opacity: 0.2;
  z-index: 100;
}
.hide {
  display: none;
}
