.container {
  height: 100%;
  min-height: calc(100vh - 143px);
  background-color: var(--secondary-color);
  display: flex;
  justify-content: center;
}
.wrapper {
  width: 100%;
  max-width: 1250px;
  display: flex;
  justify-content: space-between;
  padding: 50px 0px 0px 40px;
}
.textContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.firstRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}
.textContainer h1 {
  font-size: 60px;
  line-height: 75px;
  color: var(--tertiary-color);
  letter-spacing: -0.04em;
}
.textContainer p {
  font-size: 30px;
  line-height: 35px;
  letter-spacing: -0.04em;
  color: var(--tertiary-color);
  margin-bottom: 30px;
}
.textContainer ul {
  margin: 0px;
  padding: 0px;
}
.textContainer ul li {
  padding: 0px;
  margin: 0px 0px 8px 0px;
  list-style-type: square;
  list-style-position: inside;
  font-size: 15px;
  line-height: 22px;
  text-decoration-line: underline;
  color: #0f0864;
  cursor: pointer;
}
.subscribeContainerMob {
  display: none;
}

@media screen and (max-width: 992px) {
  .wrapper {
    padding: 50px 10px 0px 10px;
  }
  .textContainer h1 {
    font-size: 50px;
  }
  .textContainer p {
    font-size: 26px;
  }
}
@media screen and (max-width: 768px) {
  .subscribeContainerWeb {
    display: none;
  }
  .subscribeContainerMob {
    display: block;
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 480px) {
  .wrapper {
    padding: 25px 10px 0px 10px;
  }
  .textContainer h1 {
    font-size: 35px;
    margin-bottom: 0px;
    line-height: 75px;
  }
  .textContainer p {
    font-size: 20px;
    margin-bottom: 30px;
    line-height: 30px;
  }
  .textContainer ul li {
    margin: 0px 0px 10px 0px;
    line-height: 24px;
    font-size: 16px;
    max-width: 300px;
    width: 100%;
  }
  .subscribeContainerMob {
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 372px) {
  .container {
    min-height: calc(100vh);
  }  
}