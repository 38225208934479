.mainHeader {
  display: flex;
  align-items: center;
  justify-content: center;
}
.primaryHeader {
  background-color: var(--primary-color);
}
.secondaryHeader {
  background-color: var(--secondary-color);
}
.tertiaryHeader {
  background-color: var(--tertiary-color);
}
.mainHeader a {
  text-decoration: none;
  color: unset;
  position: relative;
}
.mainHeader a:hover {
  opacity: 0.8;
}
.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0px;
  max-width: 1250px;
  width: 100%;
}
.logoContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.logoContainer img {
  width: 62px;
  height: 62px;
  width: 53px;
  height: 53px;
  border-radius: 100%;
}
.logoContainer p {
  font-family: "Sacramento", cursive;
  font-size: 33px;
  margin-left: 12px;
}
.primaryHeader .logoContainer p {
  color: #ffffff;
}
.secondaryHeader .logoContainer p {
  color: var(--tertiary-color);
}
.tertiaryHeader .logoContainer p {
  color: #ffffff;
}
.container nav {
  display: flex;
  align-items: center;
}
.container nav ul {
  display: flex;
  align-items: center;
  padding: 0px;
  margin: 0px;
}
.container nav li {
  font-family: "Noto Sans JP", sans-serif;
  font-weight: 400;
  padding: 0px;
  margin: 0px;
  list-style: none;
  font-size: 14px;
  margin: 0px 18px;
}
.primaryHeader nav li {
  color: #ffffff;
}
.secondaryHeader nav li {
  color: var(--tertiary-color);
}
.tertiaryHeader nav li {
  color: #ffffff;
}
.hoverDot {
  position: absolute;
  border-radius: 100%;
  width: 4px;
  height: 4px;
  left: 0;
  right: 0;
  margin: auto;
  margin-top: 5px;
}
.primaryHeader .hoverDot {
  background-color: #ffffff;
}
.secondaryHeader .hoverDot {
  background-color: var(--tertiary-color);
}
.tertiaryHeader .hoverDot {
  background-color: #ffffff;
}
.menuIcon {
  display: none;
}
@media screen and (max-width: 992px) {
  .container {
    padding: 15px 10px;
  }
  .container nav {
    display: none;
  }
  .menuIcon {
    display: block;
    width: 30px;  
  }
  .emailButtonContainer {
    display: none;
  }
  .logoContainer img {
    width: 45px;
    height: 45px;
  }
  .logoContainer p {
    font-size: 26px;
    margin-left: 12px;
  }
  .primaryMenu rect {
    fill: #ffffff !important;
  }
  .secondaryHeader rect {
    fill: var(--tertiary-color) !important;
  }
}
@media screen and (max-width: 480px) {
  .logoContainer img {
    width: 50px;
    height: 50px;
  }
  .logoContainer p {
    font-size: 34px;
    margin-left: 10px;
  }
  .menuIcon {
    width: 30px;
  }
}